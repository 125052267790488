<template>
  <div class="main-container">
    <h1>Nasza oferta</h1>
    <div class="d-grid">
      <div v-for="f in features" :key="f.title" class="feature">
        <div class="d-flex">
          <div class="square"></div>
          <h3>{{ f.title }}</h3>
        </div>
        <p>{{ f.p }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurOffer",
  data() {
    return {
      features: [
        {title: 'Webowe aplikacje GIS', p:'Jestemśmy ekspertami w projektowaniu i wdrażaniu webowych aplikacji GIS. Tworzymy rozwiązania dopasowane do potrzeb naszych klientów.', icon: '', isHovered: false},
        {title: 'Mobilne aplikacje GIS', p:'Posiadamy duże doświadczenie w projektowaniu i wdrażaniu aplikacji mobilnych na systemy Android i iOS.', icon: '', isHovered: false},
        {title: 'Doradztwo dot. GIS w IT', p:'Wiemy jak optymalnie wykorzystywać istniejące rozwiązania do wdrażania systemów z elementami GIS i map. Udzielemy wsparcia firmom IT w zakresie map i GIS.', icon: '', isHovered: false},
        {title: 'Szkolenia dot. GIS', p:'Jesteśmy ekspertami w dziedzinie GIS, dlatego pragniemy dzielić się naszą wiedzą. Organizujemy szkolenia dotyczące zagadnień GIS-owych dla firm i instytucji publicznych.', icon: '', isHovered: false},
      ]
    }
  },
  methods: {}
}
</script>
<style scoped>

.main-container{
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #a7bbde;
  background-size: cover;
  background-position: center;
  background-image: url('~@/assets/images/104053.jpg');
}

h1{
  color: black;
  font-size: 40px;
}

.square{
  display: none;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background-color: #3fa9c2;
}

.feature {
  margin: 30px;
  min-width: 300px;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

  .d-grid {
    margin: 0 auto;
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}


@media (max-width: 800px) {
  .d-grid {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

</style>