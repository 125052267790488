<template>
  <div class="container">

    <div class="container background-image">
      <div class="content">
        <h1>Systemy informacji przestrzennej</h1>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "TheOpening",
  data() {

  },
  methods: {}
}
</script>
<style scoped>
h1{
  font-size: 60px;
  text-shadow: #25242a 3px 4px;
}
.hs1 {
  font-size: 70px;
  font-weight: 600;
  color: #fdfdfe;
  text-shadow: 0px 0px 5px #c5c4b1, 0px 0px 10px #eae7df;
}
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content {
  z-index: 2;
  color: white;
  padding: 8px;
}

.img-size {
  height: 120px;
}
h1{
  font-size: 50px;
  text-shadow: #25242a 3px 4px;
}

.background-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url('~@/assets/images/nasa.jpg');
}

@media (max-width: 876px) {
  .img-size {
    max-width: 100%;
  }


  .container {
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  z-index: 1;
}
  .content {
  z-index: 2;
  padding: 20px;
}
}

</style>