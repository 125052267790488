<template>
  <nav-bar ref="navBar"></nav-bar>
  <the-hero id="home" ></the-hero>
  <our-offer id="oferta"></our-offer>
  <contact-form id="kontakt"></contact-form>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import TheHero from "@/components/TheHero.vue";
import OurOffer from "@/components/OurOffer.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'LandingPage',
  components: {
    OurOffer,
    NavBar,
    TheHero,
    ContactForm,
  },
  data(){
    return {
      menuItems: [
          {label: 'home', active: true},
          {label: 'oferta', active: false},
          {label: 'kontakt', active: false},
      ]
    }
  },
  methods:{
    menuItemActivated(menuItem){
      console.log('Activeted menu');
      console.log(menuItem);

    }
  },
  mounted() {
    this.$refs.navBar.menuItems = this.menuItems
  }
}
</script>
<style scoped>
.icon-class {
  width: 24px;
  height: 24px;
}
</style>
