<template>
  <div class="navbar">
      <img src="@/assets/images/mapit.svg" alt="Logo mapit" height="50px" class="navbar-brand">
      <div class="menu">
        <div :class="i.active? 'active' : ''" v-for="i in menuItems" :key="i.label">
          <a :href="'#'+i.label">{{ i.label }}</a></div>
      </div>
      <div></div>
      <div class="drawer" v-if="drawerIsOpen">
        <div :class="i.active? 'active' : ''" v-for="i in menuItems" :key="i.label">{{ i.label }}</div>
      </div>
  </div>
</template>
<script>

export default {
  name: 'NavBar',
  components:{
  },
  data(){
    return {
      menuItems: [
        {label: 'exampleLabel', active: true},
        {label: 'exampleLabel2', active: false},
        {label: 'exampleLabel3', active: false},
      ],
      drawerIsOpen: false,
    }
  },
  methods:{
    selectItem(item) {
      this.menuItems.forEach(i => {
        i.active = false;
      });
      item.active = true;
      this.$emit("menuItemActivated", item);
    }
  }
}
</script>
<style scoped>

a {
    text-decoration: none;
    color: inherit;

}
.navbar{
  height: 80px;
  width: 100%;
  background-color: white;
  align-items: center;
  display: flex;
}
.navbar-brand{
  width: 200px;
  flex: 1;
}
.menu{
  flex: 4;
  margin-right: 16px;
  margin-left: 16px;
  display: flex;
  justify-content: flex-end;
}
.menu div{
  padding-left: 16px;
  color: #696767;
  font-size: large;
}
.menu div{
  padding-left: 16px;
  color: #696767;
  font-size: large;
}
.menu div:hover{
  color: #000000;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .menu{
    display: none;
  }
}
.active{
  color: black !important;
}

</style>