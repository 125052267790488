<template>
  <div class="container">
    <h2>Zapraszamy do kontaktu</h2>
    <form @submit.prevent="submitForm">
      <div class="form-control">
        <label for="firstName">Imię:</label>
        <input type="text" id="firstName" v-model="form.firstName" required>
      </div>
      <div class="form-control">
        <label for="lastName">Nazwisko:</label>
        <input type="text" id="lastName" v-model="form.lastName" required>
      </div>
      <div class="form-control">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="form.email" required>
      </div>
      <div class="form-control">
        <label for="message">Wiadomość:</label>
        <textarea id="message" v-model="form.message" required></textarea>
      </div>
      <div class="form-control">
        <label for="subject">Czego dotyczy wiadomość:</label>
        <select id="subject" v-model="form.subject" required>
          <option v-for="option in subjects" :value="option.value" :key="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>
      <div class="form-control">
          <button  type="submit">Wyślij wiadomość</button>
      </div>
    </form>
    <div v-if="submitted">
      <p>Formularz został wysłany pomyślnie!</p>
    </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        subject: ''
      },
      subjects: [
        { text: 'Szkolenie', value: 'szkolenie' },
        { text: 'Zamówienie oprogramowania', value: 'software' },
        { text: 'Inny temat', value: 'inne' },
      ],
      submitted: false
    };
  },
  methods: {
    submitForm() {
        // eslint-disable-next-line no-undef
        grecaptcha.ready(() => {
            // eslint-disable-next-line no-undef
            grecaptcha.execute('6LfydP0pAAAAANcSBKViT_ugD1Cg3-EKESv02rqf', {action: 'submit'}).then((token) => {
            console.log(this.form)
              const formData = {
                  ...this.form,
                  recaptchaToken: token
              };
              axios.post('/backend/api/send-message', formData)
                .then(response => {
                  console.log(response.data)
                  this.submitted = true;
                  this.form = {
                firstName: '',
                lastName: '',
                email: '',
                message: '',
                subject: ''
              }
                  alert('Formularz został wysłany pomyślnie!');

                })
                .catch(error => {
                  console.error('Wystąpił błąd przy wysyłaniu formularza:', error);
                  alert('Wystąpił błąd przy wysyłaniu formularza. Spróbuj ponownie później.');
                });
          });
        });


    }
  }
};
</script>

<style scoped>
.container{
  padding-top: 30px;
  padding-bottom: 60px;
  width: 100%;
}
form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.form-control{
  padding: 8px;
  display: flex;
  flex-direction: column;

}
.form-control input,
.form-control select,
.form-control textarea{
  border: 2px solid #ccc;
  border-radius: 4px;
  min-height: 36px;
  line-height: 30px;
  resize: vertical;
}

.form-control select{
  border: 2px solid #ccc;
  min-height: 40px;
  max-width: 100%;
  padding: 4px;
}

.form-control select:focus,
.form-control textarea:focus,
.form-control input:focus
{
  padding: 4px;
    border: 1px solid #3fa9c2;
    outline: 2px solid #3fa9c2;
}

.form-control label{
  text-align: start;
  padding: 4px;
  font-weight: 600;
}

.form-control button {
  padding: 12px;
  background-color: #3fa9c2;
  color: white;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 12px;
  font-weight: 700;
}

button:hover {
  background-color: #3fa9c2;
}
</style>
